import React from 'react'
interface data {
  content: any
  onClick: any
  id: string
}

function FormBtn({ content, onClick, id }: data) {
  return (
    <>
      <div className="w-[100%] text-center m-auto">
        <button
        id={id}
          onClick={onClick}
          className="bg-[#715BA8] w-[40%] my-4 rounded-full md:text-xl md:font-bold text-[white] sm:py-[8px] sm:px-[40px] py-[5px] px-[35px] my-[40px] hover:bg-[#715BA8]-600 active:bg-[#715BA8]-700 focus:outline-none focus:ring focus:ring-violet-300"
        >
          {content}
        </button>
      </div>
    </>
  )
}

export default FormBtn