import * as React from "react"
import PropTypes from "prop-types"
import Slider, { SliderThumb } from "@mui/material/Slider"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import Tooltip from "@mui/material/Tooltip"
import Box from "@mui/material/Box"

function ValueLabelComponent(props) {
  const { children, value } = props

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  )
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
}

// const iOSBoxShadow =
//   "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)"

// const marks = [
//   {
//     value: 0,
//   },
//   {
//     value: 20,
//   },
//   {
//     value: 37,
//   },
//   {
//     value: 100,
//   },
// ]

// const IOSSlider = styled(Slider)(({ theme }) => ({
//   color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
//   height: 2,
//   padding: "15px 0",
//   "& .MuiSlider-thumb": {
//     height: 28,
//     width: 28,
//     backgroundColor: "#fff",
//     boxShadow: iOSBoxShadow,
//     "&:focus, &:hover, &.Mui-active": {
//       boxShadow:
//         "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
//       // Reset on touch devices, it doesn't add specificity
//       "@media (hover: none)": {
//         boxShadow: iOSBoxShadow,
//       },
//     },
//   },
//   "& .MuiSlider-valueLabel": {
//     fontSize: 12,
//     fontWeight: "normal",
//     top: -6,
//     backgroundColor: "unset",
//     color: theme.palette.text.primary,
//     "&:before": {
//       display: "none",
//     },
//     "& *": {
//       background: "transparent",
//       color: theme.palette.mode === "dark" ? "#fff" : "#000",
//     },
//   },
//   "& .MuiSlider-track": {
//     border: "none",
//   },
//   "& .MuiSlider-rail": {
//     opacity: 0.5,
//     backgroundColor: "#bfbfbf",
//   },
//   "& .MuiSlider-mark": {
//     backgroundColor: "#bfbfbf",
//     height: 8,
//     width: 1,
//     "&.MuiSlider-markActive": {
//       opacity: 1,
//       backgroundColor: "currentColor",
//     },
//   },
// }))

export const PrettoSlider = styled(Slider)({
  color: "#715BA8",
  height: 8,
  borderRadius: "0px",
  "& .MuiSlider-track": {
    border: "none",
    borderRadius: "0px",
  },
  "& .MuiSlider-thumb": {
    height: 25,
    width: 25,
    backgroundColor: "#715BA8",
    // border: '2px solid currentColor',
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "0px",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
})

// const AirbnbSlider = styled(Slider)(({ theme }) => ({
//   color: "#3a8589",
//   height: 3,
//   padding: "13px 0",
//   "& .MuiSlider-thumb": {
//     height: 27,
//     width: 27,
//     backgroundColor: "#fff",
//     border: "1px solid currentColor",
//     "&:hover": {
//       boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
//     },
//     "& .airbnb-bar": {
//       height: 9,
//       width: 1,
//       backgroundColor: "currentColor",
//       marginLeft: 1,
//       marginRight: 1,
//     },
//   },
//   "& .MuiSlider-track": {
//     height: 3,
//   },
//   "& .MuiSlider-rail": {
//     color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
//     opacity: theme.palette.mode === "dark" ? undefined : 1,
//     height: 3,
//   },
// }))

// function AirbnbThumbComponent(props) {
//   const { children, ...other } = props
//   return (
//     <SliderThumb {...other}>
//       {children}
//       <span className="airbnb-bar" />
//       <span className="airbnb-bar" />
//       <span className="airbnb-bar" />
//     </SliderThumb>
//   )
// }

// AirbnbThumbComponent.propTypes = {
//   children: PropTypes.node,
// }

// export default function CustomizedSlider() {
//   return (
//     <Box sx={{ width: 320 }}>
//       <Typography gutterBottom>iOS</Typography>
//       <IOSSlider
//         aria-label="ios slider"
//         defaultValue={60}
//         marks={marks}
//         valueLabelDisplay="on"
//       />
//       <Box sx={{ m: 3 }} />
//       <Typography gutterBottom>pretto.fr</Typography>
//       <PrettoSlider
//         valueLabelDisplay="auto"
//         aria-label="pretto slider"
//         defaultValue={20}
//       />
//       <Box sx={{ m: 3 }} />
//       <Typography gutterBottom>Tooltip value label</Typography>
//       <Slider
//         valueLabelDisplay="auto"
//         components={{
//           ValueLabel: ValueLabelComponent,
//         }}
//         aria-label="custom thumb label"
//         defaultValue={20}
//       />
//       <Box sx={{ m: 3 }} />
//       <Typography gutterBottom>Airbnb</Typography>
//       <AirbnbSlider
//         components={{ Thumb: AirbnbThumbComponent }}
//         getAriaLabel={index =>
//           index === 0 ? "Minimum price" : "Maximum price"
//         }
//         defaultValue={[20, 40]}
//       />
//     </Box>
//   )
// }
