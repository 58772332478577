import React from 'react'


function Card({ image, content, setopenComponent, onClick, value, id }: any) {
  return (
    <>
      <div id={id} onClick={() => {
        setopenComponent(value)
        onClick()
      }} className="flex flex-col cursor-pointer justify-center text-center m-5 flex-wrap border-neutral-200 sm:border-[8px] border-[4px] rounded-3xl border-solid hover:shadow-md ">
        <div className="flex flex-col sm:p-6 m-5">
          <img className="" src={image} />
        </div>
        <div className="text-black pt-2 w-52 h-20 m-auto  text-[16px] text-center">
          {content}
        </div>
      </div>
    </>
  )
}

export default Card
