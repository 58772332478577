import { Children, PropsWithChildren, useMemo } from 'react';
import { StylableProp } from '../../../utils/stylable-prop';

export interface TableHeaderProps {
  cols?: number;
}

export function TableHeader(props: PropsWithChildren<StylableProp<TableHeaderProps>>) {
  const cols = useMemo(() => Children.count(props.children), [props.children]);

  return (
    <header className={`grid bg-brand text-white rounded-t-lg md:rounded-t-2xl ${props.className}`}>
      {props.children}
    </header>
  );
}

export function TableHeaderItem(props: PropsWithChildren<StylableProp>) {
  return <span className={`p-4 md:p-6 text-sm md:text-2xl text-center ${props.className}`}>{props.children}</span>;
}
