import { PropsWithChildren } from 'react';
import { StylableProp } from '../../../utils/stylable-prop';
import { TableHeader, TableHeaderItem } from './TableHeader';

export interface TableRowItem {
  title: string;
  current: string;
  new: string;
}

export interface TableRowProps {
  item: TableRowItem;
}

export function TableRow(
  props: PropsWithChildren<StylableProp<TableRowItem & { isEven: boolean }>>
) {
  return (
    <>
      <div className="md:hidden font-bold my-4">{props.title}</div>

      <TableHeader className="md:hidden grid-cols-2">
        <TableHeaderItem>Current Financial Situation</TableHeaderItem>
        <TableHeaderItem>Your new Financial Situation</TableHeaderItem>
      </TableHeader>

      <div
        className={`grid grid-cols-2 items-center mb-10 md:mb-0 md:grid-cols-3 bg-neutral-100 ${
          props.isEven ? 'md:bg-neutral-100' : 'md:bg-neutral-200'
        } rounded-b-lg md:rounded-none md:last-of-type:rounded-b-2xl ${props.className}`}
      >
        <TableItem className="hidden md:block">{props.title}</TableItem>
        <TableItem>{props.current}</TableItem>
        <TableItem>{props.new}</TableItem>
      </div>
    </>
  );
}

export function TableItem(props: PropsWithChildren<StylableProp>) {
  return (
    <span className={`p-4 md:p-8 text-sm md:text-lg font-semibold text-center ${props.className}`}>
      {props.children}
    </span>
  );
}
