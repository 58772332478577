import React from 'react';
// import wallet from '../../assets/nuborrowwallet.png';
import wallet from '../../../assets/nuborrowwallet.png';
import nuborrowcard from '../../../assets/nuborrowcard.png';
import nuborrowcollection from '../../../assets/nuborrowcollection.png';
import nuborrowbank from '../../../assets/nuborrowbank.png';
import Card from './Cardcontent/Card';
import { strings } from '../../../utils/String';
import Questions from "./Questions/index"
// import ProgressBar from "./ProgressBar"

interface props {
  setopenComponent: React.Dispatch<React.SetStateAction<number>>,
  setProduct: React.Dispatch<React.SetStateAction<string>>
}



function CardsCompoOne({ setopenComponent, setProduct }: props) {

  function onClickCard1(){
    setProduct('Home Equity Loan - Cash Out')
    document.getElementById("view")?.scrollIntoView()
  }
  function onClickCard2(){
    setProduct('Home Equity Line Of Credit')
            document.getElementById("view")?.scrollIntoView()
  }
  function onClickCard3(){
    setProduct('Debt Consolidation Home Equity Loan')
    document.getElementById("view")?.scrollIntoView()
  }
  function onClickCard4(){
    setProduct('Refinance My Current Mortgage')
    document.getElementById("view")?.scrollIntoView()
  }
  return (
    <>
      {/* Questions */}
      <Questions content={"What can we help you with?"} />
      {/* Question counts */}
      <Questions counts={"Question 1 of 5"} />
      {/* ProgressBar */}
      <div className="w-[90%] mx-auto bg-gray-200 mt-2 h-2.5 #EDF3F3">
        <div className="bg-[#715BA8] h-2.5" style={{ width: '20%' }} />
      </div>
      <div className="flex cursor-pointer justify-center text-center m-5 flex-wrap">
        <div className="sm:flex">
          <div>
            <Card
              image={wallet}
              content={strings.wallet}
              setopenComponent={setopenComponent}
              value={2}
              id='btn_HEL'
              onClick = {onClickCard1}
            />
          </div>
          <div>
            <Card
              image={nuborrowcard}
              content={strings.strip}
              setopenComponent={setopenComponent}
              value={2}
              id='btn_HELOC'
              onClick = {onClickCard2}
            />
          </div>
        </div>
        <div className="sm:flex">
          <div>
            <Card
              image={nuborrowcollection}
              content={strings.collection}
              setopenComponent={setopenComponent}
              value={2}
              id='btn_debt_consolidation'
              onClick = {onClickCard3}
            />
          </div>
          <div>
            <Card
              image={nuborrowbank}
              content={strings.bank}
              setopenComponent={setopenComponent}
              value={2}
              id='btn_refinance'
              onClick = {onClickCard4}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CardsCompoOne;
