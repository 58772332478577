import React, { useState } from 'react';
// import { array } from 'yup'
import { PrettoSlider } from './Slider/Slider';
import ThirdOnwordQuestions from './Questions/ThirdOnwordQuestions';
import Questions from './Questions/index';
import FormBtn from '../Buttons/FormBtn';
import backButton from "../../../assets/back.svg"


interface Props {
  setopenComponent: React.Dispatch<any>;
  setCost: React.Dispatch<any>;
  cost: any;
  formatCurrency: (home_value: any) => string;
}

function CardsCompoFour({ setopenComponent, setCost, cost, formatCurrency }: Props) {
  const [handleLoanVal, setHandleLoanVal] = useState(25000);
  const [incrementby, setIncrementBy] = useState(5000);
  // const [million, setMillion] = useState("Over 1 Million")

  // const arrValues=[];
  // function createNumbers(e){
  //   while(e<=900000){
  //     arrValues.push(e)
  //   }
  // }
  function onClick({ value = 5 }: any) {
    setopenComponent(value);
    document.getElementById("view")?.scrollIntoView()
  }

  const loanValueHandle = (e: any) => {
    setCost(e);
    if (e === 900001) {
      setCost(1000000);
    }
  };
  // console.log(cost)

  return (
    <>
      <div className='px-2 pt-2' onClick={() => { setopenComponent(3) }}>
        <img src={backButton} alt="" className="w-10 cursor-pointer" />
      </div>
      <ThirdOnwordQuestions content={'How much money do you need?'} />

      <Questions counts={'Question 4 of 5'} />
      <div className="w-[90%] mx-auto bg-gray-200 mt-2 h-2.5 #EDF3F3">
        <div className="bg-[#715BA8] h-2.5" style={{ width: '80%' }} />
      </div>
      <div className="font-bold lg:text-4xl md:text-4xl text-center my-12 mx-auto w-[90%] md:py-16 py-8">
        <h1>
          {cost < 900001
            ? formatCurrency(cost)
            : window.innerWidth < 900
              ? 'Over one million'
              : formatCurrency(cost)}
        </h1>
      </div>
      <div className="w-[90%] mx-auto">
        <PrettoSlider
          className="h-2.5"
          // style={{ width: "90%", margin: "30px auto" }}
          onChange={(e) => {
            // if (cost !== e.target.value) {
            loanValueHandle((e.target as any).value);
            // setCost(e.target.value)
            // }
          }}
          defaultValue={handleLoanVal}
          step={incrementby}
          min={25000}
          value={cost && cost}
          // max={100000000}
          max={window.innerWidth < 900 ? 900001 : 1500000}
        />
        <div  className="w-[100%] my-0 text-center m-auto" >
          {/* <button
            onClick={({ value = 5 }: any) => {
              setopenComponent(value);
            }}
            className="bg-[#715BA8] rounded-full md:text-xl md:font-bold text-[white] sm:py-[8px] sm:px-[40px] py-[5px] px-[35px] my-[40px] hover:bg-[#715BA8]-600 active:bg-[#715BA8]-700 focus:outline-none focus:ring focus:ring-violet-300"
          >
            Next
          </button> */}
          <FormBtn
            content={"Next"}
            onClick={onClick}
            id='btn_next2'
          />
        </div>
      </div>
    </>
  );
}

export default CardsCompoFour;
