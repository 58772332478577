import React from 'react'
import homebuildOne from '../../../assets/homebuildOne.svg'
import homebuildTwo from '../../../assets/homebuildTwo.svg'
import homebuildThree from '../../../assets/homebuildThree.svg'
import buildFour from '../../../assets/buildFour.svg'
import Card from '../Page1/Cardcontent/Card'
import { strings } from '../../../utils/String'
import Questions from "../Page1/Questions/index"
// import Progress from "./ProgressBar/index"
import backButton from "../../../assets/back.svg"


function CardsCompoTwo({ setopenComponent, setPropertyType }: any) {

  function onClickCard1(){
    setPropertyType('Detached House')
    document.getElementById("view")?.scrollIntoView()
  }
  function onClickCard2(){
    setPropertyType('Semi-Detached House')
    document.getElementById("view")?.scrollIntoView()
  }
  function onClickCard3(){
    setPropertyType('Townhouse')
    document.getElementById("view")?.scrollIntoView()
  }
  function onClickCard4(){
    setPropertyType('Condo')
    document.getElementById("view")?.scrollIntoView()
  }

  return (
    <>
      <div className='px-2 pt-2' onClick={() => { setopenComponent(1) }}>
        <img src={backButton} alt="" className="w-10 cursor-pointer" />
      </div>
      <Questions content={" What can we help you with?"} />
      <Questions counts={"Question 2 of 5"} />
      <div className="w-[90%] mx-auto bg-gray-200 mt-2 h-2.5 #EDF3F3">
        <div className="bg-[#715BA8] h-2.5" style={{ width: '40%' }} />
      </div>
      {/* <Progress value={24} /> */}
      <div className="flex cursor-pointer justify-center text-center m-5 flex-wrap">
        <div className='sm:flex'>
          <div>
            <Card
              setopenComponent={setopenComponent}
              value={3}
              image={homebuildOne}
              content={strings.detached}
              id='btn1_detached'
              onClick = {onClickCard1}
            />
          </div>
          <div>
            <Card
              setopenComponent={setopenComponent}
              value={3}
              image={homebuildTwo}
              content={strings.semiDetached}
              id='btn2_semi_detached'
              onClick = {onClickCard2}
            />
          </div>
        </div>
        <div className='sm:flex'>
          <div>
            <Card
              setopenComponent={setopenComponent}
              value={3}
              image={homebuildThree}
              content={strings.town}
              id='btn3_townhouse'
              onClick = {onClickCard3}
            />
          </div>
          <div>
            <Card
              setopenComponent={setopenComponent}
              value={3}
              image={buildFour}
              content={strings.Condo}
              id='btn4_condo'
              onClick = {onClickCard4}
            />
          </div>
        </div>
      </div>

    </>
  )
}

export default CardsCompoTwo
