

interface data {
  content ?: string,
  counts ?: string,
}

// function index({content,counts}:any) {
function index(props : data) {
  return (
    <>
    <div className="font-bold mt-7 w-[90%] mx-auto md:text-3xl self-center font-raleway text-xl">
        {props.content}
    </div>
    <div className="text-xs mt-4 w-[90%] mx-auto font-normal">{props.counts}</div>

    </>
  )
}

export default index

