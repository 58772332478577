import { PropsWithChildren } from 'react';
import CreditCards from '../../../assets/credit-cards.png';
import EasyPhone from '../../../assets/easy-phone.png';
import Shield from '../../../assets/shield.png';
import { StylableProp } from '../../../utils/stylable-prop';

export default function FeaturePage1(props: StylableProp) {
  return (
    // Get Approved online today!
    <div className={`feature px-4 ${props.className}`}>
      <h2 className="text-3xl lg:text-5xl">
        Get <span className="text-brand">Approved </span> online today!
        {/* <span className="text-brand">MILLION</span> in debt over the last 10 years. */}
      </h2>
      <p className="my-4 lg:mt-12 lg:mb-8 text-lg lg:text-xl">
        With Nuborrow you get a customized borrowing power report that includes your credit score,
        estimated equity in your home, and the best mortgage options available.
      </p>
      <ul>
        <ListItem icon={EasyPhone}>
          Easily access your free credit score, home equity report, and pre-approved options on our
          mobile app.
        </ListItem>
        <ListItem icon={CreditCards}>
          No credit or income requirements needed to be approved.
        </ListItem>
        <ListItem icon={Shield}>
          Safe and secure process. Nuborrow is an A+ Better Business ranked company with 1,000s of
          Google reviews.
        </ListItem>
      </ul>
    </div>
  );
}

interface ListItem {
  icon: string | JSX.Element;
}

const ListItem = (props: PropsWithChildren<ListItem>) => {
  return (
    <li className="flex gap-4 sm:gap-8 items-center my-4 lg:mb-16 text-lg lg:text-2xl font-raleway">
      <div className="block">
        {typeof props.icon === 'string' ? (
          <img className="h-16 w-16 md:h-20 md:w-20" src={props.icon} alt="icon" />
        ) : (
          props.icon
        )}
      </div>
      <div className="flex-1">{props.children}</div>
    </li>
  );
};
