import { graphql, HeadFC, useStaticQuery } from 'gatsby';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import ChartPage1 from '../sections/page-1/chart/ChartPage1';
import FeaturePage1 from '../sections/page-1/feature/FeaturePage1';
// import CardsCompoOne from "../components/InteractivePart/CardsCompoOne"
// import CardsCompoTwo from "../components/InteractivePart/CardsCompoTwo"
// import CardsCompoThree from "../components/InteractivePart/CardsCompoThree"
// import CustomizedSlider from "../components/InteractivePart/Slider/Slider.tsx"
import Index from '../components/InteractivePart/Page1/DynamicSection/index';

const sizeClass = 'mx-auto';

function IndexPage() {
  return (
    <main>
      <Header className={sizeClass} />

      <div className={`container py-6 lg:py-16 ${sizeClass}`}>
        <section className="flex flex-col xl:flex-row-reverse gap-8 lg:gap-16">
          <FeaturePage1 className="flex-1" />
          <div className="interactive flex-1 bg-white border-neutral-200 xl:border-8 lg:rounded-3xl">
            <Index />
          </div>
        </section>
      </div>
      <div className="chart bg-white">
        <section className={`container ${sizeClass}`}>
          <ChartPage1 />
        </section>
      </div>
      <Footer className={sizeClass} />
    </main>
  );
}

export default IndexPage;

export const Head: HeadFC = () => <title>Nuborrow.com | Home Equity Made Easy</title>;
