import React from 'react'

interface data {
  content ?: string,
}

function ThirdOnwordQuestions(props : data) {
  return (
    <>
    <div className="font-bold mt-24 font-raleway text-center px-12 w-[100%] md:w-[90%] mx-auto md:text-4xl text-xl">
          {props.content}
        </div>
    </>
  )
}

export default ThirdOnwordQuestions