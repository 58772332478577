import React, { useEffect, useState } from 'react'
// Liberaries**********************
import axios from 'axios'
import PhoneInput from 'react-phone-number-input/input';
import Swal from 'sweetalert2'
import Autocomplete from "react-google-autocomplete";
// Components
import ThirdOnwordQuestions from './Questions/ThirdOnwordQuestions'
import Questions from "./Questions/index"
import backButton from "../../../assets/back.svg"



interface props {
  setName: React.Dispatch<string>,
  setContact: React.Dispatch<React.SetStateAction<string>>,
  setEmail: React.Dispatch<React.SetStateAction<string>>,
  setinpAddress: React.Dispatch<React.SetStateAction<string>>
  setzipCode: React.Dispatch<React.SetStateAction<string>>
  // setCost: React.Dispatch<React.SetStateAction<string>>
  setPlace: React.Dispatch<React.SetStateAction<string>>
  setState: React.Dispatch<React.SetStateAction<string>>
  setCity: React.Dispatch<React.SetStateAction<string>>
  propertyType: string
  handleLandingPageData: any
  unitNumber: any
  setUnitNumber: React.Dispatch<any>
  setopenComponent: React.Dispatch<any>;
  name: string;
  contact: string;
  email: string;
  place: string;
}


function CardsCompoFive({
  setName,
  setContact,
  setEmail,
  setinpAddress,
  handleLandingPageData,
  setzipCode,
  setState,
  setCity,
  setPlace,
  propertyType,
  unitNumber,
  setUnitNumber,
  setopenComponent,
  name,
  contact,
  email,
  place
}: props) {
  // Form Validation Error
  // errors
  const [nameE, setNameE] = useState('')
  const [nameError, setNameError] = useState('')

  const [phoneE, setPhoneE] = useState('')
  const [PhoneError, setPhoneError] = useState('')

  const [emailE, setEmailE] = useState('')
  const [emailError, setEmailError] = useState('')
  const [validEmail, setValidEmail] = useState('')

  const [address, setAddress] = useState<string>()
  const [coordinate, setCoordinate] = useState({})


  const [addressE, setAddressE] = useState('')
  const [addressError, setAddressError] = useState('')


  const [condoErr, setCondoErr] = useState('')
  const [condoE, setCondoE] = useState('')


  const [chekUnit, setUnit] = useState("");


  useEffect(() => {
    setNameE(name && name)
    // setName(name && name)
    setPhoneE(contact && contact)
    setEmailE(email && email)
    setAddress(place && place)
    console.log(place)
    setAddressE(place && place)
    // setCondoE(unitNumber && unitNumber)
    setCondoErr(unitNumber && unitNumber)
    // handleSelect(place && place)

  }, [])



  // -------------------------------
  // -------------------------------
  // phone validation
  const phoneNumberFunc = (n: any) => {
    // console.log(n)
    // phoneFormat(n)
    setContact(n)
    setPhoneE(n)
    setPhoneError('')
  }

  // -----------------------Validate or not----------------------------

  const checkPhoneIsCanadianValidOrNot = async () => {
    const formData = new FormData()
    formData.append('contact_no', phoneE)
    try {
      const { data } = await axios({
        method: 'post',
        url: 'https://nu2morrow.com/crm/apis/SecUser/contactvalidator',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      // console.log(formData)
      // console.log(data)
      if (data?.status !== 200) {
        return alert('Invalid number')
      }
      return data?.status
      // console.log(data?.data)
    } catch (err: any) {
      const invalidError = err.message
      // console.log(err.message)
      return Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Number is not valid!',
        // footer: '<a href="">Why do I have this issue?</a>',
      })
      // setValidPhone(invalidError)
    }
  }

  // ************auto place API**********
  const handleSelect = async (value: any) => {
    // console.log(value)
    // const results = await geocodeByAddress(value)
    // const ll = await getLatLng(results[0])

    // setCoordinate(ll)

    let address_country = ""
    let postal_code = ""
    let address_state = ""
    let address_city = ""
    let place = ""

    value?.address_components?.forEach((item: any) => {
      const countryFound = item.types.find((e: any) => e === 'country')
      const postalFound = item.types.find((e: any) => e === 'postal_code')
      const placeFound = item.types.find((e: any) => e === 'route')
      const stateFound = item.types.find(
        (e: any) => e === 'administrative_area_level_1',
      )
      const cityFound = item.types.find((e: any) => e === 'locality')

      if (countryFound) {
        address_country = item.short_name
      }
      if (postalFound) {
        postal_code = item.long_name
      }
      if (stateFound) {
        address_state = item.short_name
      }
      if (cityFound) {
        address_city = item.long_name
      }
      if (placeFound) {
        place = item.long_name
      }
    })
    // console.log("address_country :", address_country ?? "")
    // console.log("postal_code :", postal_code ?? "")
    // console.log("address_state :", address_state ?? "")
    // console.log("address_city :", address_city ?? "")
    // console.log("place :", place ?? "")
    setState(address_state)
    setzipCode(postal_code)
    setCity(address_city)
    setPlace(`${place}, ${address_city}, ${postal_code}, ${address_state}`)
  }
  // ************auto place API**********

  // *******************onclick**************
  const onClickHandle = async () => {

    if (propertyType ? !nameE && !phoneE && !emailE && !unitNumber && !addressE : !nameE && !phoneE && !emailE && !addressE) {
      let nameReq = setNameError('Name is required')
      let phoneReq = setPhoneError('Phone is required')
      let emailReq = setEmailError('Email is required')
      let condo = setCondoE('Condo is required')
      let addressReq = setAddressError('Address is required')
      return {
        nameReq,
        phoneReq,
        emailReq,
        condo,
        addressReq,
      }
    }
    // console.log(resp)
    if (!nameE) {
      return setNameError('Name is required')
    }
    if (!phoneE) {
      return setPhoneError('Phone is required')
    }

    //  ***********************

    if (phoneE.length < 12) {
      return setPhoneError('Please enter valid phone number')
    }
    if (!emailE) {
      return setEmailError('Email is required')
    } else if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailE) === false
    ) {
      return setValidEmail('Enter the valid email')
    }
    if (!unitNumber) {
      setCondoE('Unit number is required')
    }
    if (!address) {
      return setAddressError('Address is required')
      // console.log("Address is empty**********************")
    }
    if (!addressE) {
      return setAddressError('Address is required')
    }
    const resp = await checkPhoneIsCanadianValidOrNot()

    //*****************

    if (resp === 200) {
      handleLandingPageData()
      // setAddress('')
      setAddressE('')
      setEmailE('')
      setPhoneE('')
      setNameE('')
    }
  }
  // address field check empty or not
  const chkAddressFieldEmpyOrField = (ad: any) => {
    // setAddress("")
    console.log(ad.target.value)
    setAddressE(ad)
    // setAddress(ad)
    setAddressError('')
  }



  return (
    <>
      <div className='px-2 pt-2' onClick={() => { setopenComponent(4) }}>
        <img src={backButton} alt="" className="w-10 cursor-pointer" />
      </div>
      <ThirdOnwordQuestions content={"Amazing! You're no-cost reports are waiting for you."} />
      {/* <div className="font-bold mt-7 w-[90%] mx-auto md:text-2xl text-center text-xl">
          Amazing! You're no-cost reports are waiting for you.
        </div> */}
      {/* <div className="md:text-xs text-[8px] font-normal my-4 w-[70%] mx-auto">
          ( Your best guess is ok. We will provide you with a no-cost home
          equity report )
        </div> */}
      <Questions counts={"Question 5 of 5"} />
      <div className="w-[90%] mx-auto bg-gray-200 mt-2 h-2.5 #EDF3F3">
        <div className="bg-brand h-2.5" style={{ width: '100%' }} />
      </div>
      <div className="font-bold lg:text-2xl md:text-xl text-center mx-auto w-[90%] md:py-16 py-8">
        {/* Form******************** */}
        {/* <Form /> */}
        <form>
          <div className="mb-5">
            <input
              type="text"
              placeholder="Your name"
              required
              value={name && name}

              onChange={(e) => {
                setNameE(e.target.value)
                setName(e.target.value)
                setNameError('')
              }}
              className="bg-gray-50 border text-[8px] md:[8px] lg:text-[12px] text-thin sm:w-[70%] m-auto drop-shadow-sm px-[50px] border-gray-300 text-gray-900 rounded-full focus:ring-brand focus:border-brand block w-full p-2.5  dark:border-[#EDF3F3] dark:placeholder-gray-300 dark:text-black dark:focus:ring-brand dark:focus:border-brand"
            />
            <div className="text-red-700 text-left sm:w-[65%] mx-auto text-[10px] sm:text-[12px] font-semibold">
              {nameError}
            </div>
          </div>
          <div className="mb-5">
            <PhoneInput
              className="bg-gray-50 border text-[8px] md:[8px] lg:text-[12px] text-thin sm:w-[70%] m-auto drop-shadow-sm px-[50px] border-gray-300 text-gray-900 rounded-full focus:ring-brand focus:border-brand block w-full p-2.5  dark:border-[#EDF3F3] dark:placeholder-gray-300 dark:text-black dark:focus:ring-brand dark:focus:border-brand"
              placeholder="Your Phone"
              maxlength="14"
              country="US"
              value={contact && contact}
              onChange={phoneNumberFunc}
            />
            <div className="text-red-700 text-left sm:w-[65%] mx-auto text-[10px] sm:text-[12px] font-semibold">
              {PhoneError}
            </div>
          </div>
          <div className="mb-5">
            <input
              type="email"
              value={email && email}
              placeholder={
                window.innerWidth < 1440
                  ? 'Your email'
                  : 'Your Email (to send the report)'
              }
              required
              onChange={(e) => {
                setEmailE(e.target.value)
                setEmail(e.target.value)
                setEmailError('')
                setValidEmail('')
              }}
              className="bg-gray-50 border text-[8px] md:[8px] lg:text-[12px] text-thin sm:w-[70%] m-auto drop-shadow-sm px-[50px] border-gray-300 text-gray-900 rounded-full focus:ring-brand focus:border-brand block w-full p-2.5  dark:border-[#EDF3F3] dark:placeholder-gray-300 dark:text-black dark:focus:ring-brand dark:focus:border-brand"
            />
            <div className="text-red-700 text-left sm:w-[65%] mx-auto text-[10px] sm:text-[12px] font-semibold">
              {emailError}
            </div>
            <div className="text-red-700 text-left sm:w-[65%] mx-auto text-[10px] sm:text-[12px] font-semibold">
              {validEmail}
            </div>
          </div>

          {/* *************************** */}

          {propertyType === "Condo" ? <div className="mb-5">
            <input
              type="text"
              placeholder="Unit Number"
              required
              value={unitNumber && unitNumber}
              onChange={(e) => {
                setUnitNumber((e.target as any).value)
                setCondoErr((e.target as any).value)
                setUnit((e.target as any).value)
                setCondoE("")
              }}
              className="bg-gray-50 border text-[8px] md:[8px] lg:text-[12px] text-thin sm:w-[70%] m-auto drop-shadow-sm px-[50px] border-gray-300 text-gray-900 rounded-full focus:ring-brand focus:border-brand block w-full p-2.5  dark:border-[#EDF3F3] dark:placeholder-gray-300 dark:text-black dark:focus:ring-brand dark:focus:border-brand"
            />
            <div className="text-red-700 text-left sm:w-[65%] mx-auto text-[10px] sm:text-[12px] font-semibold">
              {condoE}
            </div>
          </div>
            : null}

          {/* *************************** */}


          <div className="mb-5">
            <Autocomplete
              apiKey="AIzaSyDKhj1caiJVMeNgBAqbOjV97q0oMyAuRiQ"
              // style={{ width: '90%' }}
              className="bg-gray-50 border text-[8px] md:[8px] lg:text-[12px] text-thin sm:w-[70%] m-auto drop-shadow-sm px-[50px] border-gray-300 text-gray-900 rounded-full focus:ring-brand focus:border-brand block w-full p-2.5  dark:border-[#EDF3F3] dark:placeholder-gray-300 dark:text-black dark:focus:ring-brand dark:focus:border-brand"
              onPlaceSelected={(place) => {
                handleSelect(place)
                console.log(typeof (place?.formatted_address));

                setAddress(place?.formatted_address)
              }}
              placeholder={
                window.innerWidth < 1440
                  ? 'Your address'
                  : 'Your address (to make sure the home value is accurate)'
              }
              options={{
                types: ['geocode', 'establishment'],
                componentRestrictions: { country: 'ca' },
              }}
              defaultValue={place && place}
              onChange={chkAddressFieldEmpyOrField}
            // onSelect={handleSelect}
            // value={place &&place}
            />
            <div className="text-red-700 text-left sm:w-[65%] mx-auto text-[10px] sm:text-[12px] font-semibold">
              {addressError}
            </div>
          </div>
        </form>
        <div className="w-[90%] mx-auto">
          <div className="w-[100%] text-center m-auto">
            <button
              id='btn_submit'
              className="bg-brand rounded-full md:text-xl md:font-bold text-[white] sm:py-[8px] sm:px-[40px] py-[5px] px-[35px] my-[0px] hover:bg-brand-600 active:bg-brand-700 focus:outline-none focus:ring focus:ring-violet-300"
              onClick={() => onClickHandle()}
            >
              Please send my free reports
            </button>
            {/* <FormBtn content={"Please send my free reports"} /> */}
          </div>
        </div>

        {/* Form******************** */}
      </div>

    </>
  )
}

export default CardsCompoFive
