import React from 'react';
import { PrettoSlider } from "./Slider/Slider"
import ThirdOnwordQuestions from "./Questions/ThirdOnwordQuestions"
import Questions from "./Questions/index"
import FormBtn from '../Buttons/FormBtn';
import backButton from "../../../assets/back.svg"




function CardsCompoThree({ setopenComponent, sethomeCost, home_value, formatCurrency }: any) {

  function onClick ({value = 4}: any){
    setopenComponent(value);
    document.getElementById("view")?.scrollIntoView()
  }
 
  return (
    <>
      <div className='px-2 pt-2' onClick={() => { setopenComponent(2) }}>
        <img src={backButton} alt="" className="w-10 cursor-pointer" />
      </div>
      <ThirdOnwordQuestions content={"How much do you think your home is worth?"} />
      <div className="md:text-xs text-[12px] font-normal text-center my-4 w-[70%] mx-auto">
        ( Your best guess is ok. We will provide you with a no-cost home equity report )
      </div>
      <Questions counts={"Question 3 of 5"} />
      <div className="w-[90%] mx-auto bg-gray-200 mt-2 h-2.5 #EDF3F3">
        <div className="bg-[#715BA8] h-2.5" style={{ width: '45%' }} />
      </div>
      <div className="font-bold lg:text-4xl md:text-4xl text-center my-12 mx-auto w-[90%] md:py-16 py-8">
        <h1>{formatCurrency(home_value)}</h1>
      </div>
      <div className="w-[90%] mx-auto">
        <PrettoSlider
          className="h-2.5"
          // style={{ width: "90%", margin: "30px auto" }}
          onChange={(e) => sethomeCost((e.target as any).value)}
          defaultValue={200000}
          step={5000}
          value={home_value && home_value}
          min={200000}
          max={1500000}
        />

        <div  className="w-[100%] text-center my-0 m-auto" >
          {/* <button
              onClick={({ value = 4 }:any) => {
                setopenComponent(value);
              }}
              className="bg-[#715BA8] rounded-full md:text-xl md:font-bold text-[white] sm:py-[8px] sm:px-[40px] py-[5px] px-[35px] my-[40px] hover:bg-[#715BA8]-600 active:bg-[#715BA8]-700 focus:outline-none focus:ring focus:ring-violet-300"
            >
              Next
            </button> */}
          <FormBtn
            content={"Next"}
            onClick={onClick}
            id='btn_next1'
          />
        </div>
      </div>

    </>
  );
}

export default CardsCompoThree;
