import Wallet from '../../../assets/wallet.png';
import { TableHeader, TableHeaderItem } from './TableHeader';
import { TableRow, TableRowItem } from './TableItem';

export default function ChartPage1() {
  return (
    <div className="wrapper p-4 pb-6">
      <header className="flex flex-col md:flex-row items-center md:items-end justify-between">
        <h3 className="text-3xl lg:text-5xl py-8 md:py-16 text-center md:text-left">
          Clients <span className="text-brand">SAVE</span> over{' '}
          <span className="text-brand">$15,000</span>
          <br className="hidden md:block" /> a year with our proven strategies.
        </h3>

        <div className="hidden md:block px-16">
          <img src={Wallet} alt="Girl with Wallet" />
        </div>
      </header>

      <TableHeader className="hidden md:grid grid-cols-3">
        <TableHeaderItem>&nbsp;</TableHeaderItem>
        <TableHeaderItem>Current Financial Situation</TableHeaderItem>
        <TableHeaderItem>Your new Financial Situation</TableHeaderItem>
      </TableHeader>

      {TABLE.map((item, index) => (
        <TableRow title={item.title} current={item.current} new={item.new} isEven={!!(index % 2)} />
      ))}

      <footer className="my-12 p-6 text-center flex flex-col text-white text-lg md:text-4xl font-raleway rounded-2xl bg-brand">
        <span className="my-3">Monthly Savings with Nuborrow: $1,560.00</span>
        <span className="my-3">Yearly Savings with Nuborrow: $18,720.00</span>
      </footer>
    </div>
  );
}

const TABLE: TableRowItem[] = [
  {
    title: '1st Mortgage Payment',
    current: '$2,000.00 a month',
    new: '$2,000.00 a month'
  },
  {
    title: 'New Loan Payment',
    current: '$0 a month',
    new: '$415.00 a month'
  },
  {
    title: 'Credit Card Debt',
    current: '$1,250.00 a month',
    new: 'No more credit card debt payments'
  },
  {
    title: 'Automobile Payments',
    current: '$750.00 a month',
    new: 'No more automobile payments'
  },
  {
    title: 'Total Payments',
    current: '$3,975.00 a month',
    new: '$2,415 a month'
  }
];
